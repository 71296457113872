import React from 'react';
import { Link } from 'react-router-dom';

export default function Contact() {
    const [width, setWidth] = React.useState(null);

    React.useEffect(() => {
        function resizeWindow () {
            const container = document.querySelector('.container');
            setWidth(container.offsetWidth / 2);
        }

        resizeWindow();

        window.addEventListener('resize', resizeWindow);

        return () => {
            window.removeEventListener('resize', resizeWindow);
        };
    }, []);

    return (
        <div className="bg-white" id="contact">
            <div className="container-fluid pr-0">
                    <div className="row contactRow"> 
                        <div className="col-md-6 contactCol">
                            <div className="contact-form">
                                <div className="section-head text-center ">
                                    <h2 className="title text-left">Contact Us</h2>
                                    <p className="text-left">
                                        Fill up the form and our Team will get back to you within 24 hours.
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label>Name</label>
                                    <input placeholder="Name" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Email Address</label>
                                    <input type="email" placeholder="Email Address" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Message</label>
                                    <textarea placeholder="Message" id="" cols="30" rows="4" className="form-control"></textarea>
                                </div>
                                <button className="btn btn-primary contact-form-button">Send</button>

                                <div className="social_list">
                                    <a href="https://goo.gl/maps/jdVafDfXA75K2HUq8" target="_blank">
                                        <span class="material-symbols-outlined">
                                            location_on
                                        </span>
                                        <span>R. Luís de Camões, Samora Correia</span>
                                    </a>
                                    <a href="tel:+351932272147">
                                        <span class="material-symbols-outlined">
                                            call
                                        </span>
                                        <span>+351932272147</span>
                                    </a>
                                    <a href="mailto:d.reyes@ihts.pt">
                                        <span class="material-symbols-outlined"> 
                                            mail
                                        </span>
                                        <span className="text">d.reyes@ihts.pt</span>
                                    </a>
                                </div>

                                <ul className="constact-social m-a0">
                                    <li><Link to="#"><i className="fa fa-facebook"></i></Link></li>
                                    <li><Link to="#"><i className="fa fa-google-plus"></i></Link></li>
                                    <li><Link to="#"><i className="fa fa-linkedin"></i></Link></li>
                                    <li><Link to="#"><i className="fa fa-instagram"></i></Link></li>
                                    <li><Link to="#"><i className="fa fa-twitter"></i></Link></li>
                                </ul>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="contact-info">
                                <iframe 
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3103.6294829150715!2d-8.87940598437559!3d38.93244545248214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1921a8fb03f02d%3A0x2dc7167b62382555!2sR.%20Lu%C3%ADs%20de%20Cam%C3%B5es%2C%20Samora%20Correia%2C%20Portugal!5e0!3m2!1sen!2sch!4v1661435911501!5m2!1sen!2sch" 
                                    width="100%" 
                                    height="100%" 
                                    style={{border: 0}} 
                                    allowFullscreen="" 
                                    loading="lazy" 
                                    referrerPolicy="no-referrer-when-downgrade">
                                </iframe>
                            </div>
                        </div>
                        {/* <div className="col-md-6">
                            <div className="contact_us_info">
                                <div className="name">
                                    <span>Dany Reyes</span>
                                    <span>Operations Manager - RHTS,Lda.</span>
                                </div>
                                <div className="tel">
                                    <span>
                                        <span class="material-symbols-outlined">
                                            call
                                        </span>
                                        <span>+351932272147 </span>
                                    </span>
                                    <span>
                                        <span class="material-symbols-outlined">
                                            call
                                        </span>
                                        <span>+351932272147 </span>
                                    </span>
                                </div>
                                <div className="email">
                                    <span>
                                        <span class="material-symbols-outlined">
                                            mail
                                        </span>
                                        <span className="text">d.reyes@ihts.pt</span>
                                    </span>
                                    <span>
                                        <span class="material-symbols-outlined">
                                            mail
                                        </span>
                                        <span className="text">d.reyes@ihts.pt</span>
                                    </span>
                                </div>
                                <div className="location">
                                    <span>
                                        <span class="material-symbols-outlined">
                                            location_on
                                        </span>
                                        <span>R. Luís de Camões, Samora Correia, Portugal</span>
                                    </span>
                                </div>

                                <ul className="list-inline m-a0">
                                    <li><Link to="#" className="site-button btnhover21 facebook circle "><i className="fa fa-facebook"></i></Link></li>
                                    <li><Link to="#" className="site-button btnhover21 google-plus circle "><i className="fa fa-google-plus"></i></Link></li>
                                    <li><Link to="#" className="site-button btnhover21 linkedin circle "><i className="fa fa-linkedin"></i></Link></li>
                                    <li><Link to="#" className="site-button btnhover21 instagram circle "><i className="fa fa-instagram"></i></Link></li>
                                    <li><Link to="#" className="site-button btnhover21 twitter circle "><i className="fa fa-twitter"></i></Link></li>
                                </ul>
                            </div>
                        
                        </div>  
                        <div className="col-md-6">
                            <div>
                            <iframe 
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3103.6294829150715!2d-8.87940598437559!3d38.93244545248214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1921a8fb03f02d%3A0x2dc7167b62382555!2sR.%20Lu%C3%ADs%20de%20Cam%C3%B5es%2C%20Samora%20Correia%2C%20Portugal!5e0!3m2!1sen!2sch!4v1661435911501!5m2!1sen!2sch" 
                                width="600" 
                                height="450" 
                                style={{border: 0}} 
                                allowFullscreen="" 
                                loading="lazy" 
                                referrerPolicy="no-referrer-when-downgrade">
                            </iframe>
                            </div>
                            <div className='ContactForm'>
                                <div className='container'>
                                    <div className='row'>
                                    <div className='col-12 text-center'>
                                        <div className='contactForm'>
                                        <form id='contact-form'>
                                            <div className='row formRow'>
                                            <div className='col-6 mb-3'>
                                                <input
                                                type='text'
                                                name='name'
                                                className='form-control formInput'
                                                placeholder='Name'
                                                ></input>
                                            </div>
                                            <div className='col-6 mb-3'>
                                                <input
                                                type='email'
                                                name='email'
                                                className='form-control formInput'
                                                placeholder='Email address'
                                                ></input>
                                            </div>
                                            </div>
                                            <div className='row formRow'>
                                            <div className='col mb-3'>
                                                <input
                                                type='text'
                                                name='subject'
                                                className='form-control formInput'
                                                placeholder='Subject'
                                                ></input>
                                            </div>
                                            </div>
                                            <div className='row formRow'>
                                            <div className='col mb-3'>
                                                <textarea
                                                rows={3}
                                                name='message'
                                                className='form-control formInput'
                                                placeholder='Message'
                                                ></textarea>
                                            </div>
                                            </div>
                                            <button class="btn btn-primary" type='submit'>
                                            Submit
                                            </button>
                                        </form>
                                        </div>
                                    </div>
                                    <div class="col-"></div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                                                   
                    </div>
                
            </div>
        </div>
    );
}