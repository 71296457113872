import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HomePage from './pages/Home';


class Router extends Component {
    render() {
        return (
            
			//<BrowserRouter>
			<BrowserRouter>
                <div className="page-wraper">
                    <Switch>
                        <Route path='/' component={HomePage} />
                    </Switch>
                </div>

            </BrowserRouter>

        )
    }
}

export default Router;