import React, { useState } from 'react';
import {useRef, useEffect} from 'react';
import { ReactPhotoCollage } from "react-photo-collage";
import pic1 from "../../images/gallery/rhts/pic1.jpg";
import pic2 from "../../images/gallery/rhts/pic2.jpg";
import pic3 from "../../images/gallery/rhts/pic3.jpg";
import pic4 from "../../images/gallery/rhts/pic4.jpg";
import pic5 from "../../images/gallery/rhts/pic5.jpg";
import pic6 from "../../images/gallery/rhts/pic6.jpg";
import pic7 from "../../images/gallery/rhts/pic7.jpg";
import pic8 from "../../images/gallery/rhts/pic8.jpg";
import pic9 from "../../images/gallery/rhts/pic9.jpg";
import pic10 from "../../images/gallery/rhts/pic10.jpg";

const settings = {
    width: '100%',
    photos: [
      { source: pic1 },
      { source: pic2 },
      { source: pic3 },
      { source: pic4 },
      { source: pic5 },
      { source: pic6 },
      { source: pic7 },
      { source: pic8 },
      { source: pic9 },
      { source: pic10 },
    ],
    showNumOfRemainingPhotos: true
  };

export default function Gallery() {
    const ref = useRef(null);
    const [smallWidth, setSmallWidth] = useState(null);

    useEffect(() => {
        const resizeWindow = () => {
            const { innerWidth } = window;
            setSmallWidth(innerWidth);
        };
        resizeWindow();
        window.addEventListener('resize', resizeWindow);

        return () => {
            window.removeEventListener('resize', resizeWindow);
        }
    }, []);

    return (
        
        <div className="section-full bg-gray content-inner">
            <div className="container">
                <div className="section-head text-center ">
                    <h2 className="title mb-4">Gallery</h2>
                    <div ref={ref} style={{display: 'flex',  justifyContent:'center', alignItems:'stretch'}}>

                    {smallWidth > 750 && (
                        <ReactPhotoCollage 
                            {...settings}
                            height={['280px', '280px']} 
                            layout={[4, 4]}
                        />
                    )}

                    {(smallWidth <= 750 && smallWidth >= 450) && (
                        <ReactPhotoCollage 
                            {...settings} 
                            height={['200px', '200px']} 
                            layout={[2, 2]}
                        />
                    )}

                    {smallWidth <= 450 && (
                        <ReactPhotoCollage 
                            {...settings} 
                            height={['200px', '200px']} 
                            layout={[1, 1]}
                        />
                    )}
                    </div>   
                </div>
            </div>
        </div>
    );
}