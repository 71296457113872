import React from 'react';

export default function Index() {
    return (
        <div className='equipment'>
        
            {equipmentData && equipmentData.map((item, key) => (
               <Equipments
                    key={key} 
                    bgClassName="bg-white" 
                    title={item.title}
                    description={item.descriptions}
                    images={item.images}
                    leftImage={item.left}
                    lists={(
                        <ul className='equipment-lists'>
                            {item.lists.map(item => (
                                <EquipmentList>
                                    <span>{item}</span> 
                                </EquipmentList>
                            ))}
                        </ul>
                    )}
                /> 
            ))}   
        
        </div>


   );
}

function EquipmentList({ children }) {
    return (
        <li>
            <img src={require('../../images/icon/icons8-checkmark-50.png')} />
            <div>{children}</div>  
        </li>
    );
}

function Equipments({ bgClassName, images, lists, title, description, leftImage = true }) {

    const Description = ({ left = false }) => {
        const [descriptionWidth, setDescriptionWidth] = React.useState(null);

        React.useEffect(() => {
            function onResizeWindow() {
                const containerSize = document.querySelector('.container');
                setDescriptionWidth(containerSize.offsetWidth / 2);
            }
            onResizeWindow();
            window.addEventListener('resize', onResizeWindow);
            return () => {
                window.removeEventListener('resize', onResizeWindow);
            }
        }, []);

        return (
            <div className="col-md-6 p-0">
                <div className="p-5" style={{ maxWidth: descriptionWidth, marginLeft: left ? 'auto' : '' }}>
                    <h3>{title}</h3>
                    <p style={{ textAlign: 'justify' }}>{description}</p>
                    {lists}
                </div>
            </div>
        );
    };

    const Images = () => {
        const [image, setImage] = React.useState(images[0]);
        const [activeIndex, setActiveIndex] = React.useState(0);

        return (
            <div className="col-md-6 p-0">
                <div className="current-image-view h-100 eqpItem" style={{ backgroundImage: `url(${image})`, backgroundSize: '100% 100%' }}>
                    <div className='image-thumbnails'>
                    {images.map((src, key) => (
                            <img 
                                key={key} 
                                src={src}  
                                className={activeIndex === key ? 'active' : 'inActive' }
                                onClick={() => {
                                    setImage(images[key]);
                                    setActiveIndex(key);
                                }}
                            />
                        ))} 
                    </div>
                </div>
            </div> 
        )
    };

    return (
        <div className={'section-full content-inner p-0 ' + bgClassName} id="equipments">
            <div className="container-fluid">
                <div className="row">
                    {leftImage ? (
                        <>
                            <Images />
                            <Description />
                        </>
                    ) : (
                        <>
                            <Description left />
                            <Images />
                        </>
                    )}
                </div>      
            </div>
        </div>
    );
}

const equipmentData = [
    {
        title: 'Heat treatment',
        descriptions: 'RHINO currently has thirteen (30) electrical control console (Heaters by electrical resistors) each one with six (6) zone & more than 20 combustion gas train equipped with electronic devices and valves capable of blocking the flow of gas immediately in case of any failure, which are fully operational, each of them with its relevant accessories for the execution of the activity for which they are required, including they find each other:',
        lists: [
            'Chino brand temperature recorder',
            'Hotfoil brand thermocouple attachment unity (TAU)',
            'Heaters (electric resistance)',
            'Cables (primary, triple & splitter)',
            'Thermal isolation',
            'Stud pin welder',
            'Gas & Air hoses',
            '10 MM/Btu Burners',
            '3600 scfm Blowers',
            'Gas regulator',
            'Hardness test & others',
        ],
        images: [
            'https://images.unsplash.com/photo-1656968559694-ff76e749e3b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
            require('../../images/gallery/rhts/pic1.jpg'),
            require('../../images/gallery/rhts/pic2.jpg'),
            require('../../images/gallery/rhts/pic5.jpg'),
        ],
        bgWhite: 'bg-white',
        left: true,
    },

    {
        title: 'Heat treatment',
        descriptions: 'RHINO currently has thirteen (30) electrical control console (Heaters by electrical resistors) each one with six (6) zone & more than 20 combustion gas train equipped with electronic devices and valves capable of blocking the flow of gas immediately in case of any failure, which are fully operational, each of them with its relevant accessories for the execution of the activity for which they are required, including they find each other:',
        lists: [
            'Chino brand temperature recorder',
            'Hotfoil brand thermocouple attachment unity (TAU)',
            'Heaters (electric resistance)',
            'Cables (primary, triple & splitter)',
            'Thermal isolation',
            'Stud pin welder',
            'Gas & Air hoses',
            '10 MM/Btu Burners',
            '3600 scfm Blowers',
            'Gas regulator',
            'Hardness test & others',
        ],
        images: [
            'https://images.unsplash.com/photo-1656968559694-ff76e749e3b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
            require('../../images/gallery/rhts/pic1.jpg'),
            require('../../images/gallery/rhts/pic2.jpg'),
            require('../../images/gallery/rhts/pic5.jpg'),
        ],
        bgWhite: 'bg-gray',
        left: false,
    },
    {
        title: 'Heat treatment',
        descriptions: 'RHINO currently has thirteen (30) electrical control console (Heaters by electrical resistors) each one with six (6) zone & more than 20 combustion gas train equipped with electronic devices and valves capable of blocking the flow of gas immediately in case of any failure, which are fully operational, each of them with its relevant accessories for the execution of the activity for which they are required, including they find each other:',
        lists: [
            'Chino brand temperature recorder',
            'Hotfoil brand thermocouple attachment unity (TAU)',
            'Heaters (electric resistance)',
            'Cables (primary, triple & splitter)',
            'Thermal isolation',
            'Stud pin welder',
            'Gas & Air hoses',
            '10 MM/Btu Burners',
            '3600 scfm Blowers',
            'Gas regulator',
            'Hardness test & others',
        ],
        images: [
            'https://images.unsplash.com/photo-1656968559694-ff76e749e3b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
            require('../../images/gallery/rhts/pic1.jpg'),
            require('../../images/gallery/rhts/pic2.jpg'),
            require('../../images/gallery/rhts/pic5.jpg'),
        ],
        bgWhite: 'bg-white',
        left: true,
    },
];