import React from 'react';
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import {homeSliderContent8} from '../element/SliderContent'
import HomeSlider from '../element/homeSlider2' 
import ScrollToTop from '../element/scrollToTop';
import Teams from '../sections/Teams';
import Services from '../sections/Services';
import Equipments from '../sections/Equipments';
import Contact from '../sections/Contact';
import Gallery from '../sections/Gallery';

export default function Home() {
    return (
        <>
            <Header/>
            <div className="page-content bg-white home-slider-10" id="home">	
				<HomeSlider 
                    data={homeSliderContent8}
                    // contentWrapperCls="sliderStyle10"
                    // subTitleCls="text-primary"
                    // btnCls="text-primary"
                    // btnText="CONTINUE THE JOURNEY"
                />
                <Services />
                <Equipments />
                <Teams />
                <Contact />
                <Gallery />
            </div> 
            <Footer/> 
            <ScrollToTop className="style3"/>                
        </>
    );
}
