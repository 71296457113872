import React, { Component } from 'react';
import { Link } from 'react-scroll';
import Sticky from 'react-stickynode';



class Header10 extends Component {


    componentDidMount() {

        // sidebar open/close

        var btn = document.querySelector('.navicon');
        var nav = document.querySelector('.header-nav');
       
        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.header-nav > ul > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            const active = current.classList.contains("open")
            navUl.forEach(el => el.classList.remove('open'));
            //current.classList.add('open');
           
            if(active){
                current.classList.remove('open') 
                console.log("active")
            } else{
                current.classList.add('open');
                console.log("close")
            }
        }

    }

    render() {
        return (
            <>
                <header className="site-header mo-left header-transparent overlay header navstyle4">
                    <Sticky innerZ={999} enabled={true}>
                        <div className="sticky-header main-bar-wraper navbar-expand-lg">
                            <div className="main-bar clearfix headerTarget">
                                <div className="container clearfix">
                                    <div className="logo-header mostion">
                                        <Link to="/"><img src={require("../../images/HHTSS-logo.jpeg")} alt=""/></Link>
                                    </div>
                                    
                                    <button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button>
                                    
                                    <div className="dlab-quik-search ">
                                        <form action="#">
                                            <input name="search" value="" type="text" className="form-control" placeholder="Type to search"/>
                                            <span id="quik-search-remove"><i className="ti-close"></i></span>
                                        </form>
                                    </div>
                                    
                                    <div className="header-nav navbar-collapse collapse justify-content-end" id="navbarNavDropdown">
                                        <div className="logo-header d-md-block d-lg-none">
                                            <Link to="/"><img src={require("../../images/logo.png")} alt=""/></Link>
                                        </div>
                                        <ul className="nav navbar-nav">	
                                            <li className="has-mega-menu homedemo"> 
                                                <Link to="home">
                                                    Home
                                                </Link>
                                            </li>
                                            <li className="has-mega-menu"> 
                                                <Link to={"services"}>
                                                    Services
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="equipments">Equipments</Link>
                                            </li>
                                            <li className="has-mega-menu"> 
                                                <Link to={'aboutUs'}>About us</Link>
                                            </li>
                                            <li>
                                                <Link to="contact">Contact Us</Link>
                                            </li>
                                        </ul>
                                        <div className="dlab-social-icon">
                                            <ul>
                                                <li><Link className="site-button circle fa fa-facebook" to="/javascript:void(0);"></Link></li>
                                                <li><Link className="site-button  circle fa fa-twitter" to="/javascript:void(0);"></Link></li>
                                                <li><Link className="site-button circle fa fa-linkedin" to="/javascript:void(0);"></Link></li>
                                                <li><Link className="site-button circle fa fa-instagram" to="/javascript:void(0);"></Link></li>
                                            </ul>
                                        </div>		
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Sticky>
                </header>                
            </>
        )
    }
}
export default Header10;