import React from 'react';
import { Link } from 'react-router-dom';
import postWeldHT from "../../images/gallery/rhts/post-weld-heat-treatment.jpg";
import dehydrogenationHT from "../../images/gallery/rhts/dehydrogenationHT.jpg";
import preHeating from "../../images/gallery/rhts/pre heating.jpg";
import hydrogenBO from "../../images/gallery/rhts/bake out.jpg";
import hardnessTesting from "../../images/gallery/rhts/hardness testing.jpg";
import zickAnalysis from "../../images/gallery/rhts/pic5.jpg";
import analysisWL from "../../images/gallery/rhts/wind analysis.jpg";

export default function Services() {
    
    const Service = ({ delay, duration, title, description, subDescription, count }) => {
        return (
            <div className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp flex-parent" data-wow-duration={duration} data-wow-delay={delay}>
                <div className="icon-bx-wraper" data-name={count}>
                    <div className="icon-lg">
                    <Link to="#" className="icon-cell">
                        {count === "01"?<img src={postWeldHT}  style={{height: 80,width: 80, borderRadius: 40}} alt=""/>: null}
                        {count === "02"?<img src={dehydrogenationHT}  style={{height: 80,width: 80, borderRadius: 40}} alt=""/>: null}
                        {count === "03"?<img src={preHeating}  style={{height: 80,width: 80, borderRadius: 40}} alt=""/>: null}
                        {count === "04"?<img src={hydrogenBO}  style={{height: 80,width: 80, borderRadius: 40}} alt=""/>: null}
                        {count === "05"?<img src={hardnessTesting}  style={{height: 80,width: 80, borderRadius: 40}} alt=""/>: null}
                        {count === "06"?<img src={zickAnalysis}  style={{height: 80,width: 80, borderRadius: 40}} alt=""/>: null}
                        {count === "07"?<img src={analysisWL}  style={{height: 80,width: 80, borderRadius: 40}} alt=""/>: null}
                    </Link>
                    </div>
                    <div className="icon-content flex-child">
                        <h2 className="dlab-tilte">{title}</h2>
                        <p>{description} <br /> {subDescription}</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="section-full bg-gray content-inner" id="services">
            <div className="container">
                <div className="section-head text-center">
                    <h2 className="title"> Our Services</h2>
                    <p>There are many variations of passages of Lorem Ipsum typesetting industry has been the industry's standard dummy text ever since the been when an unknown printer.</p>
                </div>
                <div className="section-content row ">
                    <Service 
                        delay="0.2s"
                        duration="2s"
                        title="Postweld Heat Treatment"
                        description = "On Site - Electrical Heaters, Furnace Temporary and Burner For Large Vessel Pressures"
                        subDescription="In Site - Electrical Heaters and Furnaces"
                        count="01"
                    />
                    <Service 
                        delay="0.4s"
                        duration="2s"
                        title="Dehydrogenation Heat Treatment"
                        description="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod"
                        count="02"
                    />
                    <Service 
                        delay="0.6s"
                        duration="2s"
                        title="Pre Heating"
                        description="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod"
                        count="03"
                    />
                    <Service 
                        delay="0.6s"
                        duration="2s"
                        title="Hydrogen Bake Out"
                        description="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod"
                        count="04"
                    />
                    <Service 
                        delay="0.8s"
                        duration="2s"
                        title="Hardness Testing"
                        description="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod"
                        count="05"
                    />
                    <Service 
                        delay="0.2s"
                        duration="2s"
                        title="Zick Analysis"
                        description="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod"
                        count="06"
                    />
                    <Service 
                        delay="0.2s"
                        duration="2s"
                        title="Winds and Loaol Analysis"
                        description="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod"
                        count="07"
                    />
                </div>
            </div>
        </div>
    );
}