import Team1 from '../element/team1'
import React from 'react';

export default function Teams() {
    return (
        <div className="section-full bg-gray content-inner" id="aboutUs">
            <div className="container">
                <div className="section-head text-center ">
                    <h2 className="title"> Meet The Team</h2>
                    <p>There are many variations of passages of Lorem Ipsum typesetting industry has been the industry's standard dummy text ever since the been when an unknown printer.</p>
                </div>
                <Team1 />
            </div>
        </div>
    );
}